import React, {useEffect} from 'react';
import DocumentTitle from 'react-document-title';
import {DownloadOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';

export default () => {

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {

    }, []);

    return (
        <DocumentTitle title="鸿蒙Next尝鲜版">
            <div style={{marginBottom: 105}}>
                <div className="home-page" style={{textAlign: "center"}}>
                    <div className="home-page-wrapper">
                        <h1>感谢支持小青账</h1>
                        <br/>
                        <p>尝鲜版抢先使用新版本系统，感受新功能变化，优先下载新版本体验，直接参与软件升级。</p>
                        <p>小青账进步的每一步，都有你的参与，再次感谢支持小青账～</p>
                        <img width="210px" alt="" src="Wechat-gzh.jpeg"/>
                        <br/>
                    </div>
                    <div>
                        {contextHolder}
                        <Button style={{
                            minWidth: 110,
                            height: 40,
                            borderRadius: 20,
                            fontSize: 16,
                        }} type="primary" shape="round" icon={<DownloadOutlined/>}
                                onClick={() => {
                                    messageApi.info('请在自带浏览器打开～');
                                    window.open("https://appgallery.huawei.com/link/invite-test-wap?taskId=c307d0f9d4df175d48f47340a538e598&invitationCode=1mIevSUigxl");
                                }}
                                ghost>鸿蒙Next尝鲜版</Button>
                        <br/>
                        <br/>
                        {/*<div style={{display: state}}>*/}
                        {/*    <p><span>应用大小</span>：<span>{size}</span></p>*/}
                        {/*    <p><span>版本</span>：<span>{version}</span></p>*/}
                        {/*    <p><span>更新时间</span>：<span>{time}</span></p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );

};
